import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(default)/_assets/pc-data-deletion-banner.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(default)/_assets/sp-data-deletion-banner.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(default)/_assets/step1.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(default)/_assets/step2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(default)/_assets/step3.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(default)/_assets/step4.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(default)/_assets/tremart-feature-1.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(default)/_assets/tremart-feature-2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(default)/_assets/tremart-feature-3.svg");
